import {
  Action,
  ArticleId,
  currencyConverterAppDefID,
  Events,
  FedopsInteractions as EcomFedopsInteractions,
  SPECS,
} from '../../editor-script/constants';
import {PageMap} from '@wix/wixstores-client-core/dist/src/constants';
import {getAppVersion} from '@wix/wixstores-client-core/dist/src/app-version/appVersion';
import {Experiments} from '../experiments/Experiments';
import {AppManifest, AppManifestBuilder} from '@wix/app-manifest-builder';
import {EditorSDK, ContextParams} from '@wix/platform-editor-sdk';
import {
  configureCategoryGfpp,
  configureGridGalleryGfpp,
  configureSideCartLightboxGfpp,
  configureStorePageGfpp,
} from './configureGridGalleryGfpp';
import {
  FedopsInteractions as StoresFedopsInteractions,
  ProductPageVariantIDs,
} from '../../stores-editor-script/constants';
import {configureSlots} from './configureSlots';
import {configureMembersWidgets} from './configureMembersWidgets';
import {WIX_NEW_STORES} from '@wix/app-definition-ids';
import {getShopPagesTpaPageId, hasCategoryPageOnSite} from '../pages';

let experiments;

export function getEcomPagesConfiguration(t, version, locale) {
  return {
    [PageMap.CART]: {
      actions: [],
      settings: [
        {
          helpId: ArticleId.storesPages,
          type: 'page_info',
          title: t('Pages_Panel_pageInfo'),
          url: `https://static.parastorage.com/services/wixstores-client-worker/${version}/info-pages/cart_${locale}.html`,
        },
        {
          type: 'layout',
        },
        {
          type: 'permissions',
        },
      ],
      descriptors: {
        icon: 'storesPageType',
        orderIndex: 1,
        tooltip: t('Pages_Store_Cart_Info_WhatisText'),
      },
    },
    [PageMap.THANKYOU]: {
      actions: [],
      settings: [
        {
          helpId: ArticleId.storesPages,
          type: 'page_info',
          title: t('Pages_Panel_pageInfo'),
          url: `https://static.parastorage.com/services/wixstores-client-worker/${version}/info-pages/thankYouPage_${locale}.html`,
        },
        {
          type: 'layout',
        },
        {
          type: 'permissions',
        },
      ],
      descriptors: {
        icon: 'storesPageType',
        orderIndex: 0,
        tooltip: t('Pages_Store_Thankyou_Info_WhatisText'),
      },
    },
  };
}

function getApplicationActions(isStores, isCategorySite, hasCategoryPage, hasBlocksProductPage, t) {
  const manageStoresAction = {
    title: t('Pages_Panel_manageCTA'),
    event: Events.manageStores,
    icon: 'settingsAction',
  };
  const addShopPage = {
    title: t('NewPages_Panel_StorePages_Panel_AddShopPage_General'),
    event: Events.addShopPage,
    icon: 'addPagePagesPanel',
    type: 'add_page',
    tooltip: t('PagesPanel_StorePages_AddShopPage_MainButton_Tooltip'),
  };
  const addCategoryPage = {
    title: t('pagesPanel.stores.settings.categoryPage.optIn.button'),
    event: Events.addCategoryPage,
    icon: 'page_dynamic_icon',
    type: 'add_page',
  };
  const addBlocksProductPage = {
    title: 'Get new Product Page',
    event: Events.addBlocksProductPage,
    icon: 'page_dynamic_icon',
    type: 'add_page',
  };
  const deleteApp = {
    title: t('pagesPanel.stores.settings.deleteStore.button'),
    event: Events.deleteApp,
    icon: 'deleteAction',
    type: 'app_remove',
  };
  const applicationActions = {
    default: {
      defaultValues: [],
    },
  };
  if (isStores) {
    const defaultValues = [manageStoresAction];
    if (experiments.enabled(SPECS.ShowAddCategoryPageEntryPoint) && !hasCategoryPage) {
      defaultValues.push(addShopPage, addCategoryPage);
    } else if (!isCategorySite) {
      defaultValues.push(addShopPage);
    }

    if (experiments.enabled(SPECS.ShowAddBlocksProductPageEntryPoint) && !hasBlocksProductPage) {
      defaultValues.push(addBlocksProductPage);
    }

    defaultValues.push(deleteApp);
    applicationActions.default.defaultValues = defaultValues;
  }

  return applicationActions;
}

export async function getAppManifest({
  t,
  locale,
  token,
  hasStoresPremium,
  exps,
  appManifestBuilder,
  editorSDK,
  options,
  fedopsLogger,
  pagesPanelTitle,
  extraParams,
}: {
  t: (key: string) => string;
  locale: string;
  token: string;
  hasStoresPremium: boolean;
  exps: Experiments;
  appManifestBuilder: AppManifestBuilder;
  editorSDK: EditorSDK;
  options: ContextParams;
  fedopsLogger: Record<string, any>;
  pagesPanelTitle: string;
  extraParams?: {
    isCategorySite?: boolean;
    hasBlocksProductPage?: boolean;
  };
}) {
  const isStores = options.initialAppData.appDefinitionId === WIX_NEW_STORES;
  const interactionType = isStores
    ? StoresFedopsInteractions.StoresAppManifestCompletion
    : EcomFedopsInteractions.EcomAppManifestCompletion;
  try {
    const version = getAppVersion();
    const ecomPagesConfiguration = getEcomPagesConfiguration(t, version, locale);
    const isClassicEditor = options.origin.type === 'CLASSIC';
    const isCategorySite = extraParams?.isCategorySite;
    const shopPagesTpaId = (await getShopPagesTpaPageId(editorSDK)) || [];
    const numOfShopPages = shopPagesTpaId.length;
    const hasCategoryPage = await hasCategoryPageOnSite(editorSDK);
    const hasBlocksProductPage = extraParams?.hasBlocksProductPage;
    experiments = exps || new Experiments();

    fedopsLogger?.interactionStarted?.(interactionType);

    const customActions = [
      {
        title: t('App_Manager_Stores_First_Quick_Action'),
        actionId: Action.openAddPanel,
        icon: 'appManager_addElementsAction',
        type: 'editorActions',
      },
      {
        title: t('App_Manager_Stores_Second_Quick_Action'),
        actionId: Action.openPagesPanel,
        icon: 'appManager_pagesAction',
        type: 'editorActions',
      },
      // {
      //   title: t('App_Manager_Stores_Fourth_Quick_Action'),
      //   actionId: Action.addRelatedApps,
      //   icon: 'appManager_relatedAppsAction',
      //   type: 'editorActions',
      // },
    ];

    if (locale === 'en') {
      customActions.splice(2, 0, {
        title: t('App_Manager_Stores_Third_Quick_Action'),
        actionId: Action.findProductsToSell,
        icon: 'market',
        type: 'editorActions',
      });
    }

    const defaultPageActions =
      isCategorySite && numOfShopPages < 2 && !experiments.enabled(SPECS.AllowRemovingLastShopWhenCategoryExists)
        ? []
        : [
            'Pages_Actions_Page_Rename',
            {
              title: t('Pages_Panel_ShopPage_Settings_Delete'),
              event: Events.deleteShopPage,
              icon: 'deleteAction',
              type: 'page_remove',
            },
          ];
    const productPageVariantActions = [
      {
        title: 'Edit Rule',
        event: Events.editProductPageVariantRule,
        icon: 'variations',
        type: 'editorActions',
      },
    ];
    const pageActions = {
      default: defaultPageActions,
      [PageMap.CATEGORY]: [],
      [PageMap.PRODUCT]: [],
      [PageMap.PRODUCT_VARIANCE]: productPageVariantActions,
      [PageMap.CART]: ecomPagesConfiguration[PageMap.CART].actions,
      [PageMap.THANKYOU]: ecomPagesConfiguration[PageMap.THANKYOU].actions,
    };

    const categoryPageSettings = [
      {
        helpId: ArticleId.storesPagesForCategorySite,
        type: 'page_info',
        title: t('Pages_Panel_pageInfo'),
        url: `https://static.parastorage.com/services/wixstores-client-worker/${version}/info-pages/categoryPage_${locale}.html?token=${token}`,
      },
      {
        type: 'layout',
      },
      {
        type: 'permissions',
      },
    ];

    const shopPageSettings = [
      {
        helpId: ArticleId.storesPages,
        type: 'page_info',
      },
      {
        type: 'layout',
      },
      {
        type: 'permissions',
      },
      {
        title: t('Pages_Panel_SEO'),
        type: 'seo',
      },
    ];

    const pageSettings = {
      [PageMap.CATEGORY]: categoryPageSettings,
      [PageMap.GALLERY]: shopPageSettings,
      [PageMap.PRODUCT]: [
        {
          helpId: isCategorySite ? ArticleId.storesPagesForCategorySite : ArticleId.storesPages,
          type: 'page_info',
          title: t('Pages_Panel_pageInfo'),
          url: `https://static.parastorage.com/services/wixstores-client-worker/${version}/info-pages/productPage_${locale}.html?token=${token}`,
        },
        {
          type: 'layout',
        },
        {
          type: 'permissions',
        },
      ],
      [PageMap.CART]: ecomPagesConfiguration[PageMap.CART].settings,
      [PageMap.THANKYOU]: ecomPagesConfiguration[PageMap.THANKYOU].settings,
    };

    for (const shopPageId of shopPagesTpaId) {
      pageSettings[shopPageId] = shopPageSettings;
    }

    const variants = [
      {
        id: ProductPageVariantIDs.DuplicateProductPage,
        name: t('newProductPage.createModal.optionDefault.title'),
        src: 'https://m.media-amazon.com/images/I/51hKRJGHmPL._SX1500_.jpg',
        description: t('newProductPage.createModal.optionDefault.subtitle'),
      },
      {
        id: ProductPageVariantIDs.StartFromBlankPage,
        name: t('newProductPage.createModal.optionCode.title'),
        src: 'https://m.media-amazon.com/images/I/51hKRJGHmPL._SX1500_.jpg',
        description: t('newProductPage.createModal.optionCode.subtitle'),
      },
    ];

    const applicationSettings = {
      default: {
        displayName: pagesPanelTitle,
        helpId: isCategorySite ? ArticleId.storesPagesForCategorySite : ArticleId.storesPages,
        pageReplace: experiments.enabled(SPECS.ShowProductPageAppReflowEntryPoint) &&
          !isClassicEditor && {
            [PageMap.PRODUCT]: {
              replaceable: true,
              variantsData: {
                variants,
                shouldFilterChosenVariants: false,
                variantsHelpUrl: 'https://wix.com',
              },
            },
          },
      },
    };

    const applicationActions = getApplicationActions(
      isStores,
      isCategorySite,
      hasCategoryPage,
      hasBlocksProductPage,
      t
    );

    const categoryPageDescriptors = {
      icon: 'storesPageType',
      orderIndex: 2,
      tooltip: t('pages.store.categoryPage.hoverTooltip'),
    };
    const shopPageDescriptors = {
      icon: 'storesPageType',
      orderIndex: 2,
    };
    const pageDescriptors = {
      [PageMap.CATEGORY]: categoryPageDescriptors,
      [PageMap.GALLERY]: shopPageDescriptors,
      [PageMap.PRODUCT]: {
        icon: 'storesPageType',
        orderIndex: 3,
        tooltip: t('Pages_Store_Product_Info_WhatisText'),
      },
      [PageMap.CART]: ecomPagesConfiguration[PageMap.CART].descriptors,
      [PageMap.THANKYOU]: ecomPagesConfiguration[PageMap.THANKYOU].descriptors,
    };
    for (const shopPageId of shopPagesTpaId) {
      pageDescriptors[shopPageId] = shopPageDescriptors;
    }

    const appDescriptor = {
      mainActions: [
        {
          title: t('App_Manager_Stores_Main_CTA'),
          actionId: Action.openManageProducts,
          icon: 'variations',
        },
        {
          title: t('App_Manager_Stores_Secondary_CTA'),
          actionId: Action.openDashboard,
          icon: 'appManager_settingsAction',
        },
      ],
      customActions,
      defaultActions: {
        upgrade: hasStoresPremium
          ? {}
          : {
              upgradeType: 'SITE_UPGRADE',
              upgradeText: t('App_Manager_Stores_Upgrade_Text'),
              upgradeLinkText: t('App_Manager_Stores_Upgrade_Link'),
            },
        learnMoreKB: ArticleId.stores,
      },
    };

    const appManifest = buildAppManifest({t, editorSDK, appManifestBuilder, options});

    appManifest.pages = {
      pageActions,
      pageSettings,
      applicationSettings,
      applicationActions,
      pageDescriptors,
      sortInnerRoutes: 'no-sort',
    } as unknown as AppManifest['pages'];

    appManifest.appDescriptor = appDescriptor as unknown as AppManifest['appDescriptor'];

    fedopsLogger?.interactionEnded?.(interactionType);

    return appManifest;
  } catch (err) {
    fedopsLogger?.interactionFailed?.(interactionType, err as Error);
  }
}

function buildAppManifest({
  t,
  editorSDK,
  appManifestBuilder,
  options,
}: {
  t: (key: string) => string;
  editorSDK: EditorSDK;
  appManifestBuilder: AppManifestBuilder;
  options: ContextParams;
}): AppManifest {
  const isClassicEditor = options.origin.type === 'CLASSIC';
  const isEditorX = options.origin.type === 'RESPONSIVE';
  const configFunctions =
    isClassicEditor && experiments.enabled(SPECS.GalleryNewGFPP)
      ? [
          configureGridGalleryGfpp({t, editorSDK}),
          configureStorePageGfpp({t, editorSDK}),
          configureCategoryGfpp({t, editorSDK}),
        ]
      : [];

  if ((!isEditorX && experiments.enabled(SPECS.ConfigureSlotsInEditorSDK)) || isEditorX) {
    configFunctions.push(configureSlots);
  }

  if (experiments.enabled(SPECS.ConfigureMembersWidgets)) {
    configFunctions.push(configureMembersWidgets);
  }

  if (experiments.enabled(SPECS.SideCartNewGFPP)) {
    configFunctions.push(configureSideCartLightboxGfpp());
  }

  return configFunctions.length ? pipe(...configFunctions)(appManifestBuilder).build() : {};
}

export function handleAppsManagerActions(sdk: EditorSDK, action: Action) {
  switch (action) {
    case Action.openDashboard:
      return sdk.editor.openDashboardPanel('', {url: '', closeOtherPanels: false});
    case Action.openManageProducts:
      return sdk.editor.openDashboardPanel('', {url: 'products', closeOtherPanels: false});
    case Action.openAddPanel:
      return sdk.editor.deeplink.show('', {
        type: 'addPanel',
        params: [WIX_NEW_STORES],
      });
    case Action.openPagesPanel:
      return sdk.editor.deeplink.show('', {
        type: 'pagesPanel',
        params: [WIX_NEW_STORES],
      });
    case Action.findProductsToSell:
      return sdk.editor.openDashboardPanel('', {url: 'store/explore-products', closeOtherPanels: false});
    case Action.addRelatedApps:
      return sdk.editor.deeplink.show('', {type: 'addPanel', params: [currencyConverterAppDefID]});
  }
}

function pipe<D>(...funcs: ((param: D) => D)[]): (param: D) => D {
  return (initialUInput) => funcs.reduce((result, currentFn) => currentFn(result), initialUInput);
}
